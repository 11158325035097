import ArcBaseBlock from './base';

type Data = {
  text: string;
};

export default class ArcTextBlock extends ArcBaseBlock<Data> {
  readonly type = 'text';

  private stashedTextChanges: string | null = null;

  stashTextChanges = (text: string) => {
    this.stashedTextChanges = text;
  };

  applyStashedTextChanges = () => {
    if (this.stashedTextChanges == null) return;
    this.data.text = this.stashedTextChanges;
    this.stashedTextChanges = null;
  };
}
