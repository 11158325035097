import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.block (not (eq this.block.id @arc.selected.id)))}}\n  <Arc::Overlay::Wrapper\n    @arc={{@arc}}\n    @blok={{this.block}}\n    class=\"border border-dashed border-accent pointer-events-none\"\n    as |position|\n  >\n    <div\n      class=\"absolute -top-0.5 left-1 inline-flex items-center gap-1 px-1.5 py-0.5 text-accent text-xs font-medium bg-accent/10 backdrop-blur-sm rounded -translate-y-full z-10\"\n    >\n      <Arc::BlockIcon @blok={{this.block}} />\n      {{humanize-block-type this.block.type}}\n    </div>\n\n    {{#if this.hasPadding}}\n      <Arc::Overlay::Offset @space={{this.block.styles.padding}} @position={{position}} @type=\"padding\" />\n    {{/if}}\n\n    {{#if this.hasMargin}}\n      <Arc::Overlay::Offset @space={{this.block.styles.margin}} @position={{position}} @type=\"margin\" />\n    {{/if}}\n  </Arc::Overlay::Wrapper>\n{{/if}}", {"contents":"{{#if (and this.block (not (eq this.block.id @arc.selected.id)))}}\n  <Arc::Overlay::Wrapper\n    @arc={{@arc}}\n    @blok={{this.block}}\n    class=\"border border-dashed border-accent pointer-events-none\"\n    as |position|\n  >\n    <div\n      class=\"absolute -top-0.5 left-1 inline-flex items-center gap-1 px-1.5 py-0.5 text-accent text-xs font-medium bg-accent/10 backdrop-blur-sm rounded -translate-y-full z-10\"\n    >\n      <Arc::BlockIcon @blok={{this.block}} />\n      {{humanize-block-type this.block.type}}\n    </div>\n\n    {{#if this.hasPadding}}\n      <Arc::Overlay::Offset @space={{this.block.styles.padding}} @position={{position}} @type=\"padding\" />\n    {{/if}}\n\n    {{#if this.hasMargin}}\n      <Arc::Overlay::Offset @space={{this.block.styles.margin}} @position={{position}} @type=\"margin\" />\n    {{/if}}\n  </Arc::Overlay::Wrapper>\n{{/if}}","moduleName":"arc/components/arc/overlay/hovering.hbs","parseOptions":{"srcName":"arc/components/arc/overlay/hovering.hbs"}});
import Component from '@glimmer/component';
import type { Arc } from 'arc/arc';

interface ArcOverlayHoveringSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
  };
}

export default class ArcOverlayHoveringComponent extends Component<ArcOverlayHoveringSignature> {
  get block() {
    return this.args.arc.hovering;
  }

  get hasPadding() {
    return this.block?.styles.padding?.some((value) => value != '0');
  }

  get hasMargin() {
    return this.block?.styles.margin?.some((value) => value != '0');
  }
}
