import type { ArcBoxBlock, ArcButtonBlock, ArcGridBlock, ArcHeadingBlock, ArcImageBlock, ArcTextBlock } from './blocks';

export type ArcBlockType = 'box' | 'grid' | 'heading' | 'text' | 'button' | 'image';
export type ArcBlock = ArcBoxBlock | ArcGridBlock | ArcHeadingBlock | ArcTextBlock | ArcButtonBlock | ArcImageBlock;
export type ArcBlockRegistry = Record<ArcBlockType, ArcBlock>;

export interface ArcContentData {
  timestamp: number;
  blocks: ArcBlockData[];
}

export type ArcBlockData =
  | ArcBoxBlockData
  | ArcGridBlockData
  | ArcHeadingBlockData
  | ArcTextBlockData
  | ArcButtonBlockData
  | ArcImageBlockData;

export type SpaceUnit = number | string;

export type BaseStyles = {
  padding?: [SpaceUnit, SpaceUnit, SpaceUnit, SpaceUnit];
  margin?: [SpaceUnit, SpaceUnit, SpaceUnit, SpaceUnit];
  borderRadius?: SpaceUnit;
  backgroundColor?: string;
};

interface ArcBaseBlockData {
  id: string;
  type: ArcBlockType;
  blocks: ArcBlockData[];
  data: object;
  styles: BaseStyles;
}

export interface ArcBoxBlockData extends ArcBaseBlockData {
  type: 'box';
}

export interface ArcGridBlockData extends ArcBaseBlockData {
  type: 'grid';
  data: {
    columns:
      | '1'
      | '1:1'
      | '1:1:1'
      | '1:1:1:1'
      | '1:1:1:1:1'
      | '1:2:1'
      | '1:3:1'
      | '1:4:1'
      | '1:2'
      | '2:1'
      | '2:3'
      | '3:2'
      | '1:4'
      | '4:1';
    gap: SpaceUnit;
  };
}

export interface ArcHeadingBlockData extends ArcBaseBlockData {
  type: 'heading';
  data: {
    level: number;
    text: string;
  };
}

export interface ArcTextBlockData extends ArcBaseBlockData {
  type: 'text';
  data: {
    text: string;
  };
}

export interface ArcButtonBlockData extends ArcBaseBlockData {
  type: 'button';
  data: {
    href: string;
    text: string;
  };
}

export interface ArcImageBlockData extends ArcBaseBlockData {
  type: 'image';
  data: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
}
