import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"inline-flex rounded bg-accent text-white py-4 px-8 text-lg font-medium\" style={{this.styles}} ...attributes>\n  {{this.block.data.text}}\n</div>", {"contents":"<div class=\"inline-flex rounded bg-accent text-white py-4 px-8 text-lg font-medium\" style={{this.styles}} ...attributes>\n  {{this.block.data.text}}\n</div>","moduleName":"arc/components/arc/blocks/button.hbs","parseOptions":{"srcName":"arc/components/arc/blocks/button.hbs"}});
import Component from '@glimmer/component';
import type { ArcButtonBlock } from 'arc/arc/blocks';
import { parseStyles } from 'arc/utils/blocks';

interface ArcButtonBlockSignature {
  Element: HTMLDivElement;
  Args: {
    blok: ArcButtonBlock;
  };
}

export default class ArcButtonBlockComponent extends Component<ArcButtonBlockSignature> {
  get block() {
    return this.args.blok;
  }

  get styles() {
    return parseStyles(this.block.styles);
  }
}
