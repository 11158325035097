import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<canvas\n  class=\"absolute pointer-events-none\"\n  style={{html-safe this.styles}}\n  {{did-insert this.setup}}\n  {{did-update this.draw @position}}\n></canvas>", {"contents":"<canvas\n  class=\"absolute pointer-events-none\"\n  style={{html-safe this.styles}}\n  {{did-insert this.setup}}\n  {{did-update this.draw @position}}\n></canvas>","moduleName":"arc/components/arc/overlay/offset.hbs","parseOptions":{"srcName":"arc/components/arc/overlay/offset.hbs"}});
import Component from '@glimmer/component';
import type { Position } from './wrapper';

interface ArcOverlayOffsetSignature {
  Element: HTMLDivElement;
  Args: {
    space: [number, number, number, number];
    position: Position | null;
    type: 'margin' | 'padding';
  };
}

export default class ArcOverlayOffsetComponent extends Component<ArcOverlayOffsetSignature> {
  canvas?: HTMLCanvasElement;

  get styles() {
    const [top, right, bottom, left] = this.args.type == 'margin' ? this.args.space : [0, 0, 0, 0];

    return `
      top: -${top + 1}px;
      left: -${left + 1}px;
      right: -${right + 1}px;
      bottom: -${bottom + 1}px;
    `;
  }

  setup = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas;
  };

  draw = () => {
    if (!this.canvas) return;
    if (!this.args.position) return;

    let { width, height } = this.args.position;
    const [top, right, bottom, left] = this.args.space;

    if (this.args.type == 'margin') {
      width += left + right;
      height += top + bottom;
    }

    draw({
      canvas: this.canvas,
      size: { width, height },
      mask: {
        x: right,
        y: top,
        width: width - right - left,
        height: height - top - bottom,
      },
      type: this.args.type,
    });
  };
}

const COLORS = {
  margin: '249, 115, 22',
  padding: '5, 150, 105',
} as const;

function draw({
  canvas,
  size,
  mask,
  type,
}: {
  canvas: HTMLCanvasElement;
  size: { width: number; height: number };
  mask: { x: number; y: number; width: number; height: number };
  type: 'margin' | 'padding';
}) {
  canvas.width = size.width * 2;
  canvas.height = size.height * 2;
  canvas.style.width = `${size.width}px`;
  canvas.style.height = `${size.height}px`;

  const ctx = canvas.getContext('2d');

  if (!ctx) return;

  // Fill the canvas with a semi-transparent background
  ctx.fillStyle = `rgba(${COLORS[type]}, 0.1)`;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Set the style for dashed lines
  ctx.strokeStyle = `rgba(${COLORS[type]}, 0.8)`;
  ctx.lineWidth = 1;
  ctx.setLineDash([5, 5]);

  // Draw dashed lines across the entire canvas
  for (let i = 0; i <= canvas.width + canvas.height; i += 12) {
    ctx.beginPath();
    ctx.moveTo(i, 0);
    ctx.lineTo(0, i);
    ctx.moveTo(canvas.width, canvas.height);
    ctx.lineTo(canvas.width, canvas.height);
    ctx.stroke();
  }

  // Draw a clear rectangle mask
  ctx.globalCompositeOperation = 'destination-out';
  ctx.fillStyle = 'rgba(0, 0, 0, 1)'; // Opaque color to clear the area
  ctx.fillRect(mask.x * 2, mask.y * 2, mask.width * 2, mask.height * 2);

  // Reset the composite operation to default
  ctx.globalCompositeOperation = 'source-over';
}
