import { htmlSafe } from '@ember/template';
import type { ArcBlock } from 'arc/arc/types';

export function parseStyles(styles: ArcBlock['styles']) {
  const stylesString = [
    styles.padding ? `padding: ${styles.padding.map((value) => `${value}px`).join(' ')};` : '',
    styles.margin ? `margin: ${styles.margin.map((value) => `${value}px`).join(' ')};` : '',
    styles.borderRadius ? `border-radius: ${styles.borderRadius}px;` : '',
    styles.backgroundColor ? `background-color: ${styles.backgroundColor};` : '',
  ]
    .filter(Boolean)
    .join(' ');

  return htmlSafe(stylesString);
}
