import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (component (concat \"arc/blocks/\" this.block.type)) as |BlockComponent|}}\n  <BlockComponent\n    @arc={{@arc}}\n    @blok={{this.block}}\n    data-block=\"{{this.block.id}}\"\n    draggable=\"true\"\n    class=\"arc-{{this.block.type}}-{{this.block.id}}\n      {{if (eq @arc.hovering.id this.block.id) 'z-10'}}\n      {{if (eq @arc.dragging.id this.block.id) 'opacity-40'}}\"\n    ...attributes\n  />\n{{/let}}", {"contents":"{{#let (component (concat \"arc/blocks/\" this.block.type)) as |BlockComponent|}}\n  <BlockComponent\n    @arc={{@arc}}\n    @blok={{this.block}}\n    data-block=\"{{this.block.id}}\"\n    draggable=\"true\"\n    class=\"arc-{{this.block.type}}-{{this.block.id}}\n      {{if (eq @arc.hovering.id this.block.id) 'z-10'}}\n      {{if (eq @arc.dragging.id this.block.id) 'opacity-40'}}\"\n    ...attributes\n  />\n{{/let}}","moduleName":"arc/components/arc/block.hbs","parseOptions":{"srcName":"arc/components/arc/block.hbs"}});
import Component from '@glimmer/component';
import type { Arc } from 'arc/arc';
import type { ArcBlock } from 'arc/arc/types';

interface ArcBlockSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blok: ArcBlock;
  };
}

export default class ArcBlockComponent extends Component<ArcBlockSignature> {
  get block() {
    return this.args.blok;
  }
}
