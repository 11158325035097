import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each @blok.blocks key=\"id\" as |block|}}\n  <Arc::Block @arc={{@arc}} @blok={{block}} />\n{{/each}}", {"contents":"{{#each @blok.blocks key=\"id\" as |block|}}\n  <Arc::Block @arc={{@arc}} @blok={{block}} />\n{{/each}}","moduleName":"arc/components/arc/blocks.hbs","parseOptions":{"srcName":"arc/components/arc/blocks.hbs"}});
import Component from '@glimmer/component';
import type { Arc } from 'arc/arc';
import type { ArcBlock } from 'arc/arc/types';

interface ArcBlocksSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blok: ArcBlock;
  };
}

export default class ArcBlocksComponent extends Component<ArcBlocksSignature> {
  get block() {
    return this.args.blok;
  }
}
