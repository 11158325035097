import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-invalid-interactive }}\n<div\n  class=\"max-w-none prose\"\n  {{on \"keydown\" (stop-propagation)}}\n  {{did-insert this.setup}}\n  {{will-destroy this.cleanup}}\n  ...attributes\n>\n  {{html-safe @content}}\n</div>", {"contents":"{{! template-lint-disable no-invalid-interactive }}\n<div\n  class=\"max-w-none prose\"\n  {{on \"keydown\" (stop-propagation)}}\n  {{did-insert this.setup}}\n  {{will-destroy this.cleanup}}\n  ...attributes\n>\n  {{html-safe @content}}\n</div>","moduleName":"arc/components/arc/quill.hbs","parseOptions":{"srcName":"arc/components/arc/quill.hbs"}});
import Component from '@glimmer/component';
import Quill from 'quill';

interface ArcQuillSignature {
  Element: HTMLDivElement;
  Args: {
    content: string;
    onChange: (content: string) => void;
  };
}

export default class ArcQuillComponent extends Component<ArcQuillSignature> {
  quill?: Quill;

  handleChange = () => {
    console.log(this.quill?.root.innerHTML);
    this.args.onChange(this.quill?.root.innerHTML || '');
  };

  setup = (element: HTMLDivElement) => {
    this.quill = new Quill(element, {
      theme: 'bubble',
      formats: ['bold', 'code', 'italic', 'link', 'list', 'strike', 'underline'],
    });

    this.quill.on('text-change', this.handleChange);
  };

  cleanup = () => {
    this.quill?.off('text-change', this.handleChange);
  };
}
