import { helper } from '@ember/component/helper';
import { htmlSafe as htmlSafeEmber } from '@ember/template';

function htmlSafe(value) {
  return htmlSafeEmber(value);
}

export default helper(function htmlSafeHelper([value]) {
  return htmlSafe(value);
});
