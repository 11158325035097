import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"min-h-[24px]\" style={{this.styles}} ...attributes>\n  <Arc::Blocks @arc={{@arc}} @blok={{this.block}} />\n</div>", {"contents":"<div class=\"min-h-[24px]\" style={{this.styles}} ...attributes>\n  <Arc::Blocks @arc={{@arc}} @blok={{this.block}} />\n</div>","moduleName":"arc/components/arc/blocks/box.hbs","parseOptions":{"srcName":"arc/components/arc/blocks/box.hbs"}});
import Component from '@glimmer/component';
import type { ArcBoxBlock } from 'arc/arc/blocks';
import type { Arc } from 'arc/arc';
import { parseStyles } from 'arc/utils/blocks';

interface ArcBoxBlockSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blok: ArcBoxBlock;
  };
}

export default class ArcBoxBlockComponent extends Component<ArcBoxBlockSignature> {
  get block() {
    return this.args.blok;
  }

  get styles() {
    return parseStyles(this.block.styles);
  }
}
