import ArcBaseBlock from './base';

type Data = {
  level: number;
  text: string;
};

export default class ArcHeadingBlock extends ArcBaseBlock<Data> {
  readonly type = 'heading';

  private stashedTextChanges: string | null = null;

  stashTextChanges = (text: string) => {
    this.stashedTextChanges = text;
  };

  applyStashedTextChanges = () => {
    if (this.stashedTextChanges == null) return;
    this.data.text = this.stashedTextChanges;
    this.stashedTextChanges = null;
  };
}
