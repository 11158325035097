import { tracked } from 'tracked-built-ins';
import type { ArcBlock, ArcContentData } from './types';
import { blockFactory, insertBlock, removeBlock } from './utils';

export default class ArcContent {
  @tracked timestamp: number;
  blocks = tracked<ArcBlock>([]);

  constructor({ timestamp, blocks }: ArcContentData) {
    this.timestamp = timestamp;

    for (const block of blocks) {
      this.blocks.push(blockFactory(block));
    }
  }

  /**
   * Insert a new block before or after an existing block
   * @param block the new block
   * @param needle block to insert before or after
   * @param position
   */
  insertBlock(block: ArcBlock, needle: ArcBlock, position: 'before' | 'after') {
    insertBlock(this.blocks, block, needle, position);
  }

  /**
   * Remove a block from the tree
   * @param block
   */
  removeBlock(block: ArcBlock) {
    removeBlock(this.blocks, block);
  }

  /**
   * Implement toJSON to allow JSON.stringify to work with the object
   */
  toJSON() {
    return {
      timestamp: this.timestamp,
      blocks: this.blocks,
    };
  }
}
