import { findClosestBlockElement } from 'arc/utils/elements';
import { ArcBoxBlock, ArcButtonBlock, ArcGridBlock, ArcHeadingBlock, ArcImageBlock, ArcTextBlock } from './blocks';
import type {
  ArcBlock,
  ArcBlockData,
  ArcBlockType,
  ArcBoxBlockData,
  ArcButtonBlockData,
  ArcGridBlockData,
  ArcHeadingBlockData,
  ArcImageBlockData,
  ArcTextBlockData,
} from './types';

export function blockFactory(data: ArcBlockData): ArcBlock {
  switch (data.type) {
    case 'box':
      return new ArcBoxBlock(data);
    case 'grid':
      return new ArcGridBlock(data);
    case 'heading':
      return new ArcHeadingBlock(data);
    case 'text':
      return new ArcTextBlock(data);
    case 'button':
      return new ArcButtonBlock(data);
    case 'image':
      return new ArcImageBlock(data);
  }
}

let id = 1;

export function generateId(type: ArcBlockType) {
  return `${type}-${id++}`;
}

type PartialBlockData<T extends ArcBlockData> = Partial<Omit<T, 'id' | 'type' | 'styles'>> & {
  styles?: Partial<T['styles']>;
};

export function createBlock(type: 'box', data: PartialBlockData<ArcBoxBlockData>): ArcBoxBlock;
export function createBlock(type: 'grid', data: PartialBlockData<ArcGridBlockData>): ArcGridBlock;
export function createBlock(type: 'heading', data: PartialBlockData<ArcHeadingBlockData>): ArcHeadingBlock;
export function createBlock(type: 'text', data: PartialBlockData<ArcTextBlockData>): ArcTextBlock;
export function createBlock(type: 'button', data: PartialBlockData<ArcButtonBlockData>): ArcButtonBlock;
export function createBlock(type: 'image', data: PartialBlockData<ArcImageBlockData>): ArcImageBlock;
export function createBlock(type: ArcBlockType, data: PartialBlockData<ArcBlockData>) {
  return blockFactory({
    id: generateId(type),
    type,
    blocks: [],
    data: {},
    styles: {},
    ...data,
  } as ArcBlockData);
}

export function insertBlock(
  blocks: ArcBlock[],
  block: ArcBlock,
  needle: ArcBlock,
  position: 'before' | 'after',
): ArcBlock[] {
  const index = blocks.findIndex((block) => block.id == needle.id);

  if (index > -1) {
    blocks.splice(position == 'after' ? index + 1 : index, 0, block);
  } else {
    for (const child of blocks) {
      insertBlock(child.blocks, block, needle, position);
    }
  }

  return blocks;
}

export function removeBlock(blocks: ArcBlock[], block: ArcBlock) {
  const index = blocks.findIndex((b) => b.id == block.id);

  if (index > -1) {
    blocks.splice(index, 1);
  } else {
    for (const child of blocks) {
      removeBlock(child.blocks, block);
    }
  }
}

export function findBlockByElement(blocks: ArcBlock[], element?: HTMLElement): ArcBlock | undefined {
  const id = findClosestBlockElement(element)?.dataset['block'];

  if (!id) return;

  return findBlock(blocks, id);
}

export function findBlock(blocks: ArcBlock[], id: string): ArcBlock | undefined {
  for (const current of blocks) {
    if (current.id == id) return current;

    if (current.blocks) {
      const block = findBlock(current.blocks, id);

      if (block) return block;
    }
  }
}

export function findParentBlock(blocks: ArcBlock[], block: ArcBlock): ArcBlock | undefined {
  for (const current of blocks) {
    if (current.blocks.find((current) => current.id == block.id)) {
      return current;
    }

    const parent = findParentBlock(current.blocks, block);

    if (parent) {
      return parent;
    }
  }
}

export function cloneBlock(block: ArcBlock): ArcBlock {
  const copy = JSON.parse(JSON.stringify(block));
  updateBlockIds(copy);

  return blockFactory(copy);
}

function updateBlockIds(block: ArcBlockData) {
  block.id = generateId(block.type);

  for (const child of block.blocks) {
    updateBlockIds(child);
  }
}
