import { tracked } from 'tracked-built-ins';
import { ArcHeadingBlock, ArcTextBlock } from './blocks';
import ArcContent from './content';
import type { ArcBlock, ArcBlockType, ArcContentData } from './types';
import { cloneBlock, createBlock, findParentBlock } from './utils';

export default class Arc {
  @tracked content: ArcContent;

  @tracked selected?: ArcBlock;
  @tracked hovering?: ArcBlock;
  @tracked editing?: ArcBlock;
  @tracked dragging?: ArcBlock;
  @tracked draggingOver?: ArcBlock;
  @tracked draggingOverPosition?: 'top' | 'bottom';

  private clipboard?: ArcBlock;
  private history: string[] = [];

  constructor(data: ArcContentData) {
    this.content = new ArcContent(data);
  }

  get blocks() {
    return this.content.blocks;
  }

  select = (block: ArcBlock) => {
    console.log('💡', 'Select', block);

    // Don't select if we're editing
    if (this.editing == block) return;

    if (this.selected == block) {
      this.deselect();
      return;
    }

    this.update();
    this.selected = block;
  };

  deselect = () => {
    console.log('💡', 'Deselect');
    this.selected = undefined;
  };

  hover = (block: ArcBlock) => {
    console.log('💡', 'Hover', block);
    this.hovering = block;
  };

  hoverParent = (block: ArcBlock) => {
    console.log('💡', 'Hover parent', block);
    const parent = findParentBlock(this.blocks, block);
    this.hovering = parent;
  };

  edit = (block: ArcBlock) => {
    console.log('💡', 'Edit', block);
    this.editing = block;
    this.selected = block;
  };

  update = (block?: ArcBlock) => {
    block = block || this.editing;

    if (block instanceof ArcHeadingBlock || block instanceof ArcTextBlock) {
      this.addHistoryEntry();
      block.applyStashedTextChanges();
    }

    this.editing = undefined;
  };

  addBefore = (before: ArcBlock, type: ArcBlockType) => {
    console.log('💡', 'Adding a block before', type, before);
    const block = this.createBlock(type);

    if (block) {
      this.addHistoryEntry();
      this.content.insertBlock(block, before, 'before');
      this.select(block);
    }
  };

  addAfter = (after: ArcBlock, type: ArcBlockType) => {
    console.log('💡', 'Adding a block after', type, after);
    const block = this.createBlock(type);

    if (block) {
      this.addHistoryEntry();
      this.content.insertBlock(block, after, 'after');
      this.select(block);
    }
  };

  move = (block: ArcBlock, target: ArcBlock, position: 'before' | 'after') => {
    console.log('💡', 'Moving block', block, target, position);

    if (block == target) return;

    this.deselect();

    this.addHistoryEntry();
    this.content.removeBlock(block);
    this.content.insertBlock(block, target, position);
    this.select(block);
  };

  remove = (block: ArcBlock) => {
    console.log('💡', 'Removing block', block);

    this.addHistoryEntry();
    this.content.removeBlock(block);
    this.deselect();
  };

  undo = () => {
    const previous = this.history.pop();
    console.log('💡', 'Undo', previous);

    if (!previous) return;

    this.content = new ArcContent(JSON.parse(previous));
  };

  copy = () => {
    this.clipboard = this.selected;
  };

  paste = () => {
    console.log('💡 Paste', this.clipboard, this.selected);
    if (!this.clipboard || !this.selected) return;

    const block = cloneBlock(this.clipboard);

    this.addHistoryEntry();
    this.content.insertBlock(block, this.selected, 'after');
    this.select(block);
  };

  createBlock(type: ArcBlockType): ArcBlock {
    switch (type) {
      case 'grid':
        return createBlock('grid', {
          data: { columns: '1:1', gap: 24 },
          blocks: [createBlock('box', {}), createBlock('box', {})],
        });
      case 'box':
        return createBlock('box', {});
      case 'heading':
        return createBlock('heading', { data: { level: 2, text: 'Random heading here' } });
      case 'text':
        return createBlock('text', { data: { text: 'Random text here' } });
      case 'button':
        return createBlock('button', { data: { href: '', text: 'Random Button' } });
      case 'image':
        return createBlock('image', { data: { src: '', alt: '', width: 200, height: 200 } });
    }
  }

  private addHistoryEntry() {
    console.log('🗄️', 'Push to history');
    this.history.push(JSON.stringify(this.content));
  }
}
