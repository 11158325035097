import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"grid gap-6 min-h-[24px]\" style={{this.styles}} ...attributes>\n  {{#each this.block.blocks as |block|}}\n    <Arc::Block @arc={{@arc}} @blok={{block}} />\n  {{/each}}\n</div>", {"contents":"<div class=\"grid gap-6 min-h-[24px]\" style={{this.styles}} ...attributes>\n  {{#each this.block.blocks as |block|}}\n    <Arc::Block @arc={{@arc}} @blok={{block}} />\n  {{/each}}\n</div>","moduleName":"arc/components/arc/blocks/grid.hbs","parseOptions":{"srcName":"arc/components/arc/blocks/grid.hbs"}});
import Component from '@glimmer/component';
import type { ArcGridBlock } from 'arc/arc/blocks';
import type { Arc } from 'arc/arc';
import { parseStyles } from 'arc/utils/blocks';

interface ArcGridBlockSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blok: ArcGridBlock;
  };
}

const columns: Record<ArcGridBlock['data']['columns'], string> = {
  '1': '1fr',
  '1:1': 'repeat(2, 1fr)',
  '1:1:1': 'repeat(3, 1fr)',
  '1:1:1:1': 'repeat(4, 1fr)',
  '1:1:1:1:1': 'repeat(5, 1fr)',
  '1:2:1': '1fr 2fr 1fr',
  '1:3:1': '1fr 3fr 1fr',
  '1:4:1': '1fr 4fr 1fr',
  '1:2': '1fr 2fr',
  '2:1': '2fr 1fr',
  '2:3': '2fr 3fr',
  '3:2': '3fr 2fr',
  '1:4': '1fr 4fr',
  '4:1': '4fr 1fr',
};

export default class ArcGridBlockComponent extends Component<ArcGridBlockSignature> {
  get block() {
    return this.args.blok;
  }

  get styles() {
    return `grid-template-columns: ${columns[this.block.data.columns]}; ${parseStyles(this.block.styles)}`;
  }
}
