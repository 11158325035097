import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div style={{this.styles}} ...attributes>\n  {{#if (eq @arc.editing this.block)}}\n    <Arc::Quill @content={{this.block.data.text}} @onChange={{this.block.stashTextChanges}} />\n  {{else}}\n    <div class=\"prose max-w-none\">\n      {{html-safe this.block.data.text}}\n    </div>\n  {{/if}}\n</div>", {"contents":"<div style={{this.styles}} ...attributes>\n  {{#if (eq @arc.editing this.block)}}\n    <Arc::Quill @content={{this.block.data.text}} @onChange={{this.block.stashTextChanges}} />\n  {{else}}\n    <div class=\"prose max-w-none\">\n      {{html-safe this.block.data.text}}\n    </div>\n  {{/if}}\n</div>","moduleName":"arc/components/arc/blocks/text.hbs","parseOptions":{"srcName":"arc/components/arc/blocks/text.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ArcTextBlock } from 'arc/arc/blocks';
import type { Arc } from 'arc/arc';
import { parseStyles } from 'arc/utils/blocks';

interface ArcTextBlockSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blok: ArcTextBlock;
  };
}

export default class ArcTextBlockComponent extends Component<ArcTextBlockSignature> {
  @tracked isEditing = false;

  get block() {
    return this.args.blok;
  }

  get styles() {
    return parseStyles(this.block.styles);
  }
}
