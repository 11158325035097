import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.block}}\n  <div\n    class=\"absolute top-0 left-0 z-40\"\n    style={{html-safe this.styles}}\n    {{did-insert this.setup}}\n    {{will-destroy this.cleanup}}\n    {{did-update this.update this.block.id}}\n    ...attributes\n  >\n    {{yield this.position}}\n  </div>\n{{/if}}", {"contents":"{{#if this.block}}\n  <div\n    class=\"absolute top-0 left-0 z-40\"\n    style={{html-safe this.styles}}\n    {{did-insert this.setup}}\n    {{will-destroy this.cleanup}}\n    {{did-update this.update this.block.id}}\n    ...attributes\n  >\n    {{yield this.position}}\n  </div>\n{{/if}}","moduleName":"arc/components/arc/overlay/wrapper.hbs","parseOptions":{"srcName":"arc/components/arc/overlay/wrapper.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { ArcBlock } from 'arc/arc/types';
import type { Arc } from 'arc/arc';

export type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

interface ArcOverlayWrapperSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
    blok?: ArcBlock;
    includeMargins?: boolean;
  };
  Blocks: [Position | null];
}

export default class ArcOverlayWrapperComponent extends Component<ArcOverlayWrapperSignature> {
  @tracked targetRect?: DOMRect;
  @tracked parentRect?: DOMRect;

  private resizeObserver?: ResizeObserver;

  get block() {
    return this.args.blok;
  }

  get position(): Position | null {
    if (!this.targetRect) return null;
    if (!this.parentRect) return null;

    let x = this.targetRect.left - this.parentRect.left;
    let y = this.targetRect.top - this.parentRect.top;
    let width = this.targetRect.width;
    let height = this.targetRect.height;

    if (this.args.includeMargins && this.block?.styles.margin) {
      x -= Number(this.block.styles.margin[3]);
      y -= Number(this.block.styles.margin[0]);
      width += Number(this.block.styles.margin[1]) + Number(this.block.styles.margin[3]);
      height += Number(this.block.styles.margin[0]) + Number(this.block.styles.margin[2]);
    }

    return { x, y, width, height };
  }

  get styles() {
    if (!this.position) return '';

    const { x, y, width, height } = this.position;

    return `
      transform: translate(${x}px, ${y}px);
      width: ${width}px;
      height: ${height}px;
    `;
  }

  setup = (element: HTMLDivElement) => {
    this.resizeObserver = new ResizeObserver(([entry]) => {
      this.targetRect = entry?.target.getBoundingClientRect();
      this.parentRect = element.parentElement?.getBoundingClientRect();
    });

    this.setupResizeObserver();
  };

  update = () => {
    this.setupResizeObserver();
  };

  cleanup = () => {
    this.resizeObserver?.disconnect();
    this.resizeObserver = undefined;
  };

  private setupResizeObserver = () => {
    if (!this.block) return;

    const blockElement = document.querySelector('#preview')?.shadowRoot?.querySelector(`[data-block=${this.block.id}]`);

    if (!this.resizeObserver) return;
    if (!blockElement) return;

    this.resizeObserver.disconnect();
    this.resizeObserver.observe(blockElement);
  };
}
