import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div id=\"preview\" {{did-insert this.setup}} />\n\n{{#if this.shadowRoot}}\n  {{#in-element this.shadowRoot}}\n    <link rel=\"stylesheet\" href=\"/assets/preview.css\" />\n    <div data-styles={{this.styles}}></div>\n\n    <div ...attributes>\n      <Arc::Blocks @arc={{@arc}} @blok={{@arc.content}} />\n    </div>\n  {{/in-element}}\n{{/if}}", {"contents":"<div id=\"preview\" {{did-insert this.setup}} />\n\n{{#if this.shadowRoot}}\n  {{#in-element this.shadowRoot}}\n    <link rel=\"stylesheet\" href=\"/assets/preview.css\" />\n    <div data-styles={{this.styles}}></div>\n\n    <div ...attributes>\n      <Arc::Blocks @arc={{@arc}} @blok={{@arc.content}} />\n    </div>\n  {{/in-element}}\n{{/if}}","moduleName":"arc/components/arc/preview.hbs","parseOptions":{"srcName":"arc/components/arc/preview.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Arc } from 'arc/arc';
import type { ArcBlock } from 'arc/arc/types';
import { parseStyles } from 'arc/utils/blocks';

interface ArcPreviewSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
  };
}

export default class ArcPreviewComponent extends Component<ArcPreviewSignature> {
  @tracked shadowRoot: ShadowRoot | undefined;

  private stylesheet = new CSSStyleSheet();

  get styles() {
    this.stylesheet.replaceSync('');

    const renderStyles = (blocks: ArcBlock[]) => {
      for (const block of blocks) {
        this.stylesheet.insertRule(`.arc-${block.type}-${block.id} { ${parseStyles(block.styles)} }`);

        renderStyles(block.blocks);
      }
    };

    renderStyles(this.args.arc.blocks);

    return Date.now();
  }

  setup = (element: HTMLDivElement) => {
    this.shadowRoot = element.attachShadow({ mode: 'open' });
    this.shadowRoot.adoptedStyleSheets = [this.stylesheet];
  };
}
