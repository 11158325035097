import type { SpaceUnit } from '../types';
import ArcBaseBlock from './base';

type Data = {
  columns:
    | '1'
    | '1:1'
    | '1:1:1'
    | '1:1:1:1'
    | '1:1:1:1:1'
    | '1:2:1'
    | '1:3:1'
    | '1:4:1'
    | '1:2'
    | '2:1'
    | '2:3'
    | '3:2'
    | '1:4'
    | '4:1';
  gap: SpaceUnit;
};

export default class ArcGridBlock extends ArcBaseBlock<Data> {
  readonly type = 'grid';
}
