import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';

export function stopPropagation([callback]) {
  return function (event) {
    assert(`Expect '${event}' have a 'stopPropagation' method.`, event && typeof event.stopPropagation === 'function');

    event.stopPropagation();

    if (callback) callback(event);
  };
}

export default helper(stopPropagation);
