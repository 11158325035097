import { helper } from '@ember/component/helper';
import type { ArcBlockType } from 'arc/arc/types';

const TYPES: Record<ArcBlockType, string> = {
  box: 'Box',
  button: 'Button',
  grid: 'Grid',
  heading: 'Heading',
  image: 'Image',
  text: 'Text',
};

export function humanizeBlockType(type: ArcBlockType) {
  return TYPES[type];
}

export default helper(function humanizeBlockTypeHelper([value]: [ArcBlockType]) {
  return humanizeBlockType(value);
});
