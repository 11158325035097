import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{svg-jar this.icon class=\"w-4 h-4 fill-current\"}}", {"contents":"{{svg-jar this.icon class=\"w-4 h-4 fill-current\"}}","moduleName":"arc/components/arc/block-icon.hbs","parseOptions":{"srcName":"arc/components/arc/block-icon.hbs"}});
import Component from '@glimmer/component';
import type { ArcBlock } from 'arc/arc/types';

interface ArcBlockIconSignature {
  Element: HTMLDivElement;
  Args: {
    blok: ArcBlock;
  };
}

export default class ArcBlockIconComponent extends Component<ArcBlockIconSignature> {
  get block() {
    return this.args.blok;
  }

  get icon() {
    switch (this.block.type) {
      case 'heading':
        return `h${this.block.data.level}`;
      default:
        return this.block.type;
    }
  }
}
