import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import type { ArcContentData } from 'arc/arc/types';
import { createBlock } from 'arc/arc/utils';

export default class extends Controller {
  @tracked content: ArcContentData = {
    timestamp: Date.now(),
    blocks: [
      createBlock('grid', {
        data: { columns: '4:1', gap: 0 },
        blocks: [
          createBlock('box', {
            blocks: [
              createBlock('heading', { data: { level: 1, text: 'Webcasting services you can rely on' } }),
              createBlock('text', {
                data: {
                  text: 'Our team will guide you every step of the way and deliver the best online experience for your audience with Livecasts',
                },
                styles: {
                  margin: [32, 0, 32, 0],
                },
              }),
              createBlock('button', { data: { href: '#', text: 'Get a Quote' } }),
            ],
          }),
        ],
        styles: {
          margin: [48, 0, 48, 0],
        },
      }),

      createBlock('grid', {
        data: { columns: '1:1', gap: '24px' },
        blocks: [
          createBlock('box', {
            blocks: [
              createBlock('heading', { data: { level: 3, text: 'Virtual Events' } }),
              createBlock('text', {
                data: { text: 'Hold a live interactive online event, webinar or conference' },
                styles: { margin: [8, 0, 0, 0] },
              }),
              createBlock('image', {
                data: {
                  src: 'https://www.livecasts.eu/static/a160f17aad9895b207a356ba2a0dd6f1/3c375/virtual-event.webp',
                  alt: 'Girl on a laptop',
                  width: 1200,
                  height: 716,
                },
                styles: { margin: [32, 0, 0, 0], borderRadius: 4 },
              }),
            ],
            styles: {
              padding: [32, 32, 32, 32],
              borderRadius: 6,
              backgroundColor: '#EEF7F8',
            },
          }),
          createBlock('box', {
            blocks: [
              createBlock('heading', { data: { level: 3, text: 'On-site Events' } }),
              createBlock('text', {
                data: { text: 'Extend your reach beyond your physical event capacity' },
                styles: { margin: [8, 0, 0, 0] },
              }),
              createBlock('image', {
                data: {
                  src: 'https://www.livecasts.eu/static/c0e1c8f46a1a7c1b33358374a3c78d41/3c375/on-site-event.webp',
                  alt: 'Whatever',
                  width: 1200,
                  height: 716,
                },
                styles: { margin: [32, 0, 0, 0], borderRadius: 4 },
              }),
            ],
            styles: {
              padding: [32, 32, 32, 32],
              borderRadius: 6,
              backgroundColor: '#F0F9FC',
            },
          }),
        ],
      }),
    ],
  };
}
