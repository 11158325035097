import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.block}}\n  <Arc::Overlay::Wrapper @arc={{@arc}} @blok={{this.block}} @includeMargins={{true}} class=\"pointer-events-none\">\n    <div\n      class=\"absolute left-2 right-2 h-1 bg-accent/80 z-50\n        {{if (eq @arc.draggingOverPosition 'top') '-top-0.5'}}\n        {{if (eq @arc.draggingOverPosition 'bottom') '-bottom-0.5'}}\"\n    ></div>\n  </Arc::Overlay::Wrapper>\n{{/if}}", {"contents":"{{#if this.block}}\n  <Arc::Overlay::Wrapper @arc={{@arc}} @blok={{this.block}} @includeMargins={{true}} class=\"pointer-events-none\">\n    <div\n      class=\"absolute left-2 right-2 h-1 bg-accent/80 z-50\n        {{if (eq @arc.draggingOverPosition 'top') '-top-0.5'}}\n        {{if (eq @arc.draggingOverPosition 'bottom') '-bottom-0.5'}}\"\n    ></div>\n  </Arc::Overlay::Wrapper>\n{{/if}}","moduleName":"arc/components/arc/overlay/drop-indicator.hbs","parseOptions":{"srcName":"arc/components/arc/overlay/drop-indicator.hbs"}});
import Component from '@glimmer/component';
import type { Arc } from 'arc/arc';

interface ArcOverlayDropIndicatorSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
  };
}

export default class ArcOverlayDropIndicatorComponent extends Component<ArcOverlayDropIndicatorSignature> {
  get block() {
    return this.args.arc.draggingOver;
  }
}
