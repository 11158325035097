import { tracked } from 'tracked-built-ins';
import type { ArcBlock, ArcBlockData, ArcBlockType, BaseStyles } from '../types';
import { blockFactory } from '../utils';

export default abstract class ArcBaseBlock<D extends object, S extends BaseStyles = BaseStyles> {
  readonly id: string;
  abstract readonly type: ArcBlockType;
  readonly data = tracked<D>({} as D);
  readonly styles = tracked<BaseStyles>({} as BaseStyles);
  readonly blocks = tracked<ArcBlock>([]);

  constructor({ id, blocks, data, styles }: { id: string; blocks: ArcBlockData[]; data: D; styles: S }) {
    this.id = id;

    Object.assign(this.data, data);
    Object.assign(this.styles, styles);

    for (const block of blocks) {
      this.blocks.push(blockFactory(block));
    }
  }

  toJSON() {
    return {
      id: this.id,
      type: this.type,
      blocks: this.blocks,
      data: this.data,
      styles: this.styles,
    };
  }
}
