import ArcBaseBlock from './base';

type Data = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

export default class ArcImageBlock extends ArcBaseBlock<Data> {
  readonly type = 'image';
}
