import ArcBaseBlock from './base';

type Data = {
  href: string;
  text: string;
};

export default class ArcButtonBlock extends ArcBaseBlock<Data> {
  readonly type = 'button';
}
