import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<img\n  src={{this.block.data.src}}\n  alt={{this.block.data.alt}}\n  width={{this.block.data.width}}\n  height={{this.block.data.height}}\n  class=\"w-full h-auto\"\n  style={{this.styles}}\n  ...attributes\n/>", {"contents":"<img\n  src={{this.block.data.src}}\n  alt={{this.block.data.alt}}\n  width={{this.block.data.width}}\n  height={{this.block.data.height}}\n  class=\"w-full h-auto\"\n  style={{this.styles}}\n  ...attributes\n/>","moduleName":"arc/components/arc/blocks/image.hbs","parseOptions":{"srcName":"arc/components/arc/blocks/image.hbs"}});
import Component from '@glimmer/component';
import type { ArcImageBlock } from 'arc/arc/blocks';
import { parseStyles } from 'arc/utils/blocks';

interface ArcImageBlockSignature {
  Element: HTMLDivElement;
  Args: {
    blok: ArcImageBlock;
  };
}

export default class ArcImageBlockComponent extends Component<ArcImageBlockSignature> {
  get block() {
    return this.args.blok;
  }

  get styles() {
    return parseStyles(this.block.styles);
  }
}
