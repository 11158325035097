import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex items-center gap-2\">\n  <button\n    type=\"button\"\n    class=\"inline-flex items-center gap-1 px-2 py-1 text-gray-800 hover:text-accent text-xs font-medium hover:bg-accent/10 rounded\"\n  >\n    {{svg-jar \"page\" class=\"w-4 h-4 fill-current\"}}\n    Page\n  </button>\n\n  <span class=\"text-xs text-gray-200 last:hidden\">/</span>\n\n  {{#each this.breadcrumbs key=\"id\" as |block|}}\n    <button\n      type=\"button\"\n      class=\"inline-flex items-center gap-1 px-2 py-1 text-gray-800 hover:text-accent text-xs font-medium hover:bg-accent/10 rounded\"\n      {{on \"click\" (fn @arc.select block)}}\n      {{on \"pointerover\" (fn @arc.hover block)}}\n    >\n      <Arc::BlockIcon @blok={{block}} />\n      {{humanize-block-type block.type}}\n    </button>\n\n    <span class=\"text-xs text-gray-200 last:hidden\">/</span>\n  {{/each}}\n</div>", {"contents":"<div class=\"flex items-center gap-2\">\n  <button\n    type=\"button\"\n    class=\"inline-flex items-center gap-1 px-2 py-1 text-gray-800 hover:text-accent text-xs font-medium hover:bg-accent/10 rounded\"\n  >\n    {{svg-jar \"page\" class=\"w-4 h-4 fill-current\"}}\n    Page\n  </button>\n\n  <span class=\"text-xs text-gray-200 last:hidden\">/</span>\n\n  {{#each this.breadcrumbs key=\"id\" as |block|}}\n    <button\n      type=\"button\"\n      class=\"inline-flex items-center gap-1 px-2 py-1 text-gray-800 hover:text-accent text-xs font-medium hover:bg-accent/10 rounded\"\n      {{on \"click\" (fn @arc.select block)}}\n      {{on \"pointerover\" (fn @arc.hover block)}}\n    >\n      <Arc::BlockIcon @blok={{block}} />\n      {{humanize-block-type block.type}}\n    </button>\n\n    <span class=\"text-xs text-gray-200 last:hidden\">/</span>\n  {{/each}}\n</div>","moduleName":"arc/components/arc/breadcrumbs.hbs","parseOptions":{"srcName":"arc/components/arc/breadcrumbs.hbs"}});
import Component from '@glimmer/component';
import type { Arc } from 'arc/arc';
import { findParentBlock } from 'arc/arc/utils';

interface ArcBreadcrumbsSignature {
  Element: HTMLDivElement;
  Args: {
    arc: Arc;
  };
}

export default class ArcBreadcrumbsComponent extends Component<ArcBreadcrumbsSignature> {
  get breadcrumbs() {
    const breadcrumbs = [];

    let block = this.args.arc.selected;

    while (block) {
      breadcrumbs.push(block);
      block = findParentBlock(this.args.arc.blocks, block);
    }

    return breadcrumbs.reverse();
  }
}
