import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"{{this.fontSize}} font-bold\" style={{this.styles}} ...attributes>\n  {{#if (eq @arc.editing this.block)}}\n    <Arc::Quill @content={{this.block.data.text}} @onChange={{this.block.stashTextChanges}} />\n  {{else}}\n    {{html-safe this.block.data.text}}\n  {{/if}}\n</div>", {"contents":"<div class=\"{{this.fontSize}} font-bold\" style={{this.styles}} ...attributes>\n  {{#if (eq @arc.editing this.block)}}\n    <Arc::Quill @content={{this.block.data.text}} @onChange={{this.block.stashTextChanges}} />\n  {{else}}\n    {{html-safe this.block.data.text}}\n  {{/if}}\n</div>","moduleName":"arc/components/arc/blocks/heading.hbs","parseOptions":{"srcName":"arc/components/arc/blocks/heading.hbs"}});
import Component from '@glimmer/component';
import type { ArcHeadingBlock } from 'arc/arc/blocks';
import { parseStyles } from 'arc/utils/blocks';

interface ArcHeadingBlockSignature {
  Element: HTMLDivElement;
  Args: {
    blok: ArcHeadingBlock;
  };
}

const styles: Record<ArcHeadingBlock['data']['level'], string> = {
  1: 'text-6xl',
  2: 'text-5xl',
  3: 'text-3xl',
  4: 'text-xl',
  5: 'text-lg',
  6: 'text-base',
};

export default class ArcHeadingBlockComponent extends Component<ArcHeadingBlockSignature> {
  get block() {
    return this.args.blok;
  }

  get fontSize() {
    return styles[this.block.data.level];
  }

  get style() {
    return parseStyles(this.block.styles);
  }
}
